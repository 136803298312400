body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
  /* font-family: 'Oswald', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* GLOBALS */
.App {
  text-align: center;
  background-image: -webkit-linear-gradient(top left, black, lightslategray);
  background-image: linear-gradient(to bottom right, black, lightslategray);
  opacity: 0.95;
  color: white !important;
  /* for snap-scroll */
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  overflow-y: scroll;
}

/* add padding for slide titles */

.window {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 5rem;
  min-height: 100vh;
  /* for snap-scroll */
  scroll-snap-align: center;
}

@media (max-width: 991.98px) {
  .window {
    padding: 2rem;
  }
}

.page-title {
  right: 15%;
  top: 10%;
  padding-right: 1.5em;
  cursor: pointer;
}

/* NAV SECTION */

.nav {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 2rem;
  padding: 1rem;
  left: 0;
  text-align: left;
  width: 30vw;
  height: 100vh;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  z-index: 5;
}

.nav:first-child {
  margin-top: 1em;
}

.nav-item.contact-item {
  padding-left: 7px;
}

.nav-item {
  padding-left: 1em;
  margin: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.nav-item a {
  font-size: 3.5em;
  display: block;
  position: relative;
  text-decoration: none !important;
  color: whitesmoke !important;
  opacity: 0.8;
  -webkit-transform: perspective(400px) translateZ(-4em);
          transform: perspective(400px) translateZ(-4em);
  cursor: pointer;
}

.nav-item a:hover {
  font-weight: bold;
  opacity: 1;
}

@media (max-width: 1184px) {
  /* for mobile */
  .nav {
    -webkit-flex-direction: row;
            flex-direction: row;
    font-size: 0.5em;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    width: 100vw;
    height: 15vh;
    padding: 1em;
    margin-top: 0;
    z-index: 500;
  }
  .nav:first-child {
    margin-top: 0;
  }
  .nav-item a {
    opacity: 1;
    z-index: 550;
  }
}

.nav-item a.active {
  opacity: 1;
  color: whitesmoke;
  font-weight: bold;
}

/* HOME SECTION */

.home {
  -webkit-perspective: 700px;
          perspective: 700px;
  z-index: 250;
}

.home-contents {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

/* Can add text shadow later */
.slide-title {
  font-size: 4em;
  z-index: 200;
  letter-spacing: 0.35em;
  position: absolute;
  color: whitesmoke;
  z-index: 10;
  -webkit-filter: drop-shadow(-20px 12px 4px black);
          filter: drop-shadow(-20px 12px 4px black);
}

.title-description {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: 1;
  margin-top: 11em;
  letter-spacing: 0.5em;
  margin-left: 2.75em;
}

.text {
  padding: 1em;
}

@media (max-width: 1184px) {
  .title-description {
    margin-top: 15em;
    margin-left: 3vw;
  }
}

/* WORK SECTION */

.work {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.work-preview-container {
  /* perspective: 700px; */
  /* transform-style: preserve-3d; */
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 100%;
  height: 50vh;
  left: -5%;
  margin: 0;
  margin-top: -12em;
  /* background-color: #3e3f42; */
  border-radius: 3px;

  -webkit-justify-content: center;

          justify-content: center;
  /* overflow-y: scroll; */
  overflow-x: scroll;
  overflow-y: hidden;
}

.work-stack {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  top: 65%;
  /* transform: rotateY(-30deg); */
  /* transition: 1s ease-in; */
  -webkit-transform: rotate(-35deg);
          transform: rotate(-35deg);
  z-index: 2;
}

/* .work-stack:hover {
  width: calc(100% + 2rem);
} */

.work-preview-sheet {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: stretch;
          align-content: stretch;
  width: 20em;
  height: 100%;
  background-color: transparent;
  z-index: 3;
  cursor: pointer;
  -webkit-filter: drop-shadow(-23px -17px 3px black);
          filter: drop-shadow(-23px -17px 3px black);
  border-radius: 5px;
  overflow: hidden;
  margin: 1em;
}

.work-preview-sheet.jobly {
  top: -25%;
  left: 10%;
  position: relative;
  z-index: 10;
}

.work-preview-sheet.theRelish {
  position: relative;
  top: -5%;
  left: 7%;
}

.work-preview-sheet.shoply {
  position: relative;
  left: 4%;
  z-index: 100;
  top: 25%;
}

.work-preview-image {
  position: relative;
  width: 20vw;
  display: -webkit-flex;
  display: flex;
  margin: 20px;
  z-index: 100;
  border: solid transparent 3px;
}

.work-preview-sheet:hover > .work-preview-image {
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  border: solid lightseagreen 3px;
}

.work-info-section {
  height: 25vh;
  left: 5%;
  bottom: 8%;
  position: absolute;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

@media (max-width: 1184px) {
  /* for mobile */
  .work {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: row;
            flex-direction: row;
    z-index: 50;
  }
  .work-preview-container {
    margin-top: 5em;

    width: 100vw;
    height: 50vw;
    position: relative;
    left: 0;
    -webkit-align-items: center;
            align-items: center;
    overflow-x: auto;
  }
  .work-stack {
    width: 150%;
    height: 50vh;
  }
  .work-preview-sheet {
    width: 80vw;
    height: 150%;
  }
  .work-preview-image {
    width: 40vw;
  }
  .work-info-section {
    position: relative;
    margin: 1em;
    padding: 0;
    bottom: 5%;
    height: 28vh;
    width: 90vw;
    font-size: 0.8em;
  }
  .work-intro-blurb {
    font-size: 1.7em;
    margin-top: -2em;
  }
}

.project-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  z-index: 100;
}

.visit-site {
  z-index: 200;
}

.work-intro-blurb {
  text-align: left;
}

.artistsMat {
  z-index: 500;
}

.theRelish {
  z-index: 400;
}

.jobly {
  z-index: 200;
}

.eZCart {
  z-index: 100;
}

/* CONTACT SECTION */

.contact {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.contact-click-items {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 3em;
  padding-bottom: 2em;
}

.social-block {
  display: -webkit-flex;
  display: flex;
  z-index: 10;
}

.social-block a {
  z-index: 14;
}

.social-icon {
  padding: 0.8em;
  color: lightgray;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  /* media-breakpoint-up */
  .contact-intro {
    font-size: 1.65em;
  }
  .contact-click-items {
    margin-bottom: 2.5em;
    padding-bottom: 0.5em;
  }
}

.scroll {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* font-style: underline;
  text-decoration: underline; */
  z-index: 600;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .scroll {
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    padding: 1rem;
  }
}

.page-loader-screen {
  background-color: rgb(128, 128, 128, 0);
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-position: center;
  -webkit-animation: fade-out 5s;
          animation: fade-out 5s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  /* opacity: 0; */
}

.initials {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  font-size: 4em;
  letter-spacing: 0.35em;
  margin-top: -1em;
  margin-left: 1em;
  /* transition this zoon in */
}

.full-name {
  display: visible;
  -webkit-animation: expand-name 5s ease-out;
          animation: expand-name 5s ease-out;
}

@-webkit-keyframes expand-name {
  from,
  0% {
    display: none;
  }
  20% {
    display: none;
  }
  65% {
    display: visible;
    /* letter-spacing: 0.1em; */
  }
  to {
    display: visible;
    /* letter-spacing: 0.35em; */
  }
}

@keyframes expand-name {
  from,
  0% {
    display: none;
  }
  20% {
    display: none;
  }
  65% {
    display: visible;
    /* letter-spacing: 0.1em; */
  }
  to {
    display: visible;
    /* letter-spacing: 0.35em; */
  }
}

@-webkit-keyframes fade-out {
  from,
  20% {
    background-color: black;
    opacity: 1;
    z-index: 600;
  }
  40%,
  45% {
    background-color: rgb(128, 128, 128);
    opacity: 1;
    z-index: 600;
  }
  60%,
  65% {
    background-color: white;
    z-index: 600;
  }
  to {
    background-color: rgb(128, 128, 128, 0);
  }
}

@keyframes fade-out {
  from,
  20% {
    background-color: black;
    opacity: 1;
    z-index: 600;
  }
  40%,
  45% {
    background-color: rgb(128, 128, 128);
    opacity: 1;
    z-index: 600;
  }
  60%,
  65% {
    background-color: white;
    z-index: 600;
  }
  to {
    background-color: rgb(128, 128, 128, 0);
  }
}

@media (max-width: 991.98px) {
  .initials {
    font-size: 7vw;
    margin-left: 0.25em;
  }
}

