/* NAV SECTION */

.nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 1rem;
  left: 0;
  text-align: left;
  width: 30vw;
  height: 100vh;
  justify-content: flex-start;
  z-index: 5;
}

.nav:first-child {
  margin-top: 1em;
}

.nav-item.contact-item {
  padding-left: 7px;
}

.nav-item {
  padding-left: 1em;
  margin: 1em;
  display: flex;
  align-items: center;
}

.nav-item a {
  font-size: 3.5em;
  display: block;
  position: relative;
  text-decoration: none !important;
  color: whitesmoke !important;
  opacity: 0.8;
  transform: perspective(400px) translateZ(-4em);
  cursor: pointer;
}

.nav-item a:hover {
  font-weight: bold;
  opacity: 1;
}

@media (max-width: 1184px) {
  /* for mobile */
  .nav {
    flex-direction: row;
    font-size: 0.5em;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    width: 100vw;
    height: 15vh;
    padding: 1em;
    margin-top: 0;
    z-index: 500;
  }
  .nav:first-child {
    margin-top: 0;
  }
  .nav-item a {
    opacity: 1;
    z-index: 550;
  }
}

.nav-item a.active {
  opacity: 1;
  color: whitesmoke;
  font-weight: bold;
}
