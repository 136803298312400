/* WORK SECTION */

.work {
  flex-direction: column;
}

.work-preview-container {
  /* perspective: 700px; */
  /* transform-style: preserve-3d; */
  display: flex;
  position: relative;
  width: 100%;
  height: 50vh;
  left: -5%;
  margin: 0;
  margin-top: -12em;
  /* background-color: #3e3f42; */
  border-radius: 3px;

  justify-content: center;
  /* overflow-y: scroll; */
  overflow-x: scroll;
  overflow-y: hidden;
}

.work-stack {
  display: flex;
  width: 100%;
  top: 65%;
  /* transform: rotateY(-30deg); */
  /* transition: 1s ease-in; */
  transform: rotate(-35deg);
  z-index: 2;
}

/* .work-stack:hover {
  width: calc(100% + 2rem);
} */

.work-preview-sheet {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: stretch;
  width: 20em;
  height: 100%;
  background-color: transparent;
  z-index: 3;
  cursor: pointer;
  filter: drop-shadow(-23px -17px 3px black);
  border-radius: 5px;
  overflow: hidden;
  margin: 1em;
}

.work-preview-sheet.jobly {
  top: -25%;
  left: 10%;
  position: relative;
  z-index: 10;
}

.work-preview-sheet.theRelish {
  position: relative;
  top: -5%;
  left: 7%;
}

.work-preview-sheet.shoply {
  position: relative;
  left: 4%;
  z-index: 100;
  top: 25%;
}

.work-preview-image {
  position: relative;
  width: 20vw;
  display: flex;
  margin: 20px;
  z-index: 100;
  border: solid transparent 3px;
}

.work-preview-sheet:hover > .work-preview-image {
  transition: 0.1s ease-in;
  border: solid lightseagreen 3px;
}

.work-info-section {
  height: 25vh;
  left: 5%;
  bottom: 8%;
  position: absolute;
  justify-content: left;
  transition: 0.5s ease-in-out;
}

@media (max-width: 1184px) {
  /* for mobile */
  .work {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    z-index: 50;
  }
  .work-preview-container {
    margin-top: 5em;

    width: 100vw;
    height: 50vw;
    position: relative;
    left: 0;
    align-items: center;
    overflow-x: auto;
  }
  .work-stack {
    width: 150%;
    height: 50vh;
  }
  .work-preview-sheet {
    width: 80vw;
    height: 150%;
  }
  .work-preview-image {
    width: 40vw;
  }
  .work-info-section {
    position: relative;
    margin: 1em;
    padding: 0;
    bottom: 5%;
    height: 28vh;
    width: 90vw;
    font-size: 0.8em;
  }
  .work-intro-blurb {
    font-size: 1.7em;
    margin-top: -2em;
  }
}

.project-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  z-index: 100;
}

.visit-site {
  z-index: 200;
}

.work-intro-blurb {
  text-align: left;
}

.artistsMat {
  z-index: 500;
}

.theRelish {
  z-index: 400;
}

.jobly {
  z-index: 200;
}

.eZCart {
  z-index: 100;
}
