.page-loader-screen {
  background-color: rgb(128, 128, 128, 0);
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  animation: fade-out 5s;
  animation-timing-function: ease-out;
  /* opacity: 0; */
}

.initials {
  display: flex;
  flex-wrap: wrap;
  font-size: 4em;
  letter-spacing: 0.35em;
  margin-top: -1em;
  margin-left: 1em;
  /* transition this zoon in */
}

.full-name {
  display: visible;
  animation: expand-name 5s ease-out;
}

@keyframes expand-name {
  from,
  0% {
    display: none;
  }
  20% {
    display: none;
  }
  65% {
    display: visible;
    /* letter-spacing: 0.1em; */
  }
  to {
    display: visible;
    /* letter-spacing: 0.35em; */
  }
}

@keyframes fade-out {
  from,
  20% {
    background-color: black;
    opacity: 1;
    z-index: 600;
  }
  40%,
  45% {
    background-color: rgb(128, 128, 128);
    opacity: 1;
    z-index: 600;
  }
  60%,
  65% {
    background-color: white;
    z-index: 600;
  }
  to {
    background-color: rgb(128, 128, 128, 0);
  }
}

@media (max-width: 991.98px) {
  .initials {
    font-size: 7vw;
    margin-left: 0.25em;
  }
}
