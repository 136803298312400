/* CONTACT SECTION */

.contact {
  display: flex;
  align-items: flex-end;
}

.contact-click-items {
  display: flex;
  margin-bottom: 3em;
  padding-bottom: 2em;
}

.social-block {
  display: flex;
  z-index: 10;
}

.social-block a {
  z-index: 14;
}

.social-icon {
  padding: 0.8em;
  color: lightgray;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  /* media-breakpoint-up */
  .contact-intro {
    font-size: 1.65em;
  }
  .contact-click-items {
    margin-bottom: 2.5em;
    padding-bottom: 0.5em;
  }
}
