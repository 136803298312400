.scroll {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  /* font-style: underline;
  text-decoration: underline; */
  z-index: 600;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .scroll {
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    padding: 1rem;
  }
}
