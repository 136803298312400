/* HOME SECTION */

.home {
  perspective: 700px;
  z-index: 250;
}

.home-contents {
  display: flex;
  flex-direction: column;
}

/* Can add text shadow later */
.slide-title {
  font-size: 4em;
  z-index: 200;
  letter-spacing: 0.35em;
  position: absolute;
  color: whitesmoke;
  z-index: 10;
  filter: drop-shadow(-20px 12px 4px black);
}

.title-description {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  opacity: 1;
  margin-top: 11em;
  letter-spacing: 0.5em;
  margin-left: 2.75em;
}

.text {
  padding: 1em;
}

@media (max-width: 1184px) {
  .title-description {
    margin-top: 15em;
    margin-left: 3vw;
  }
}
