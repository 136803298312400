/* GLOBALS */
.App {
  text-align: center;
  background-image: linear-gradient(to bottom right, black, lightslategray);
  opacity: 0.95;
  color: white !important;
  /* for snap-scroll */
  scroll-snap-type: x mandatory;
  overflow-y: scroll;
}

/* add padding for slide titles */

.window {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  min-height: 100vh;
  /* for snap-scroll */
  scroll-snap-align: center;
}

@media (max-width: 991.98px) {
  .window {
    padding: 2rem;
  }
}

.page-title {
  right: 15%;
  top: 10%;
  padding-right: 1.5em;
  cursor: pointer;
}
